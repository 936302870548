export default {
    pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    siteTitle: "DizWARE - Musings of my making", // Navigation and Site Title
    siteTitleAlt: "DizWARE - Musings of my making", // Alternative Site title for SEO
    siteUrl: "https://blog.dizware.dev", // Domain of your site. No trailing slash!
    siteLanguage: "en", // Language Tag on <html> element
    siteBanner: "/assets/banner.jpg", // Your image for og:image tag. You can find it in the /static folder
    defaultBg: "/assets/bg.png", // default post background header
    favicon: "src/favicon.png", // Your image for favicons. You can find it in the /src folder
    siteDescription: "Tyler Robinson (owner of DizWARE) ", // Your site description
    author: "Tyler Robinson", // Author for schemaORGJSONLD
    siteLogo: "/assets/logo.png", // Image for schemaORGJSONLD

    // Social media
    userTwitter: "@dizware", // Twitter Username - Optional
    ogSiteName: "tydiz", // Facebook Site Name - Optional
    ogLanguage: "en_US", // Facebook Language

    // Manifest and Progress color
    // See: https://developers.google.com/web/fundamentals/web-app-manifest/
    themeColor: "#3498DB",
    backgroundColor: "#2b2e3c",

    // Settings for typography.ts
    headerFontFamily: "Bitter",
    bodyFontFamily: "Open Sans",
    baseFontSize: "18px",

    // SEO Tracking
    siteFBAppID: "2281079152148677", // Facebook App ID - Optional
    Google_Tag_Manager_ID: "GTM-PB5D2JK",
    POST_PER_PAGE: 4,
};
