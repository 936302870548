import styled from "styled-components";
import rgba from "polished/lib/color/rgba";
import darken from "polished/lib/color/darken";
import lighten from "polished/lib/color/lighten";

import { media } from "../../utils/media";

export const GridRow: any = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props: any) =>
        props.background
            ? `linear-gradient(
  -185deg,
  ${rgba(darken(0.1, props.theme.colors.primary), 0.7)}, 
  ${rgba(lighten(0.1, props.theme.colors.grey.dark), 0.9)}), url(/assets/bg.png) no-repeat`
            : null};
    background-position: center;
    background-size: cover;
    padding: 2rem 4rem;
    color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
    h1 {
        color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
    }
    @media ${media.tablet} {
        padding: 3rem 3rem;
    }
    @media ${media.phone} {
        padding: 2rem 1.5rem;
    }
`;
