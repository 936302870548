import styled from "styled-components";

import { media } from "../../utils/media";

export const Homepage = styled.main`
    display: flex;
    height: 100vh;
    flex-direction: row;
    @media ${media.tablet} {
        height: 100%;
        flex-direction: column;
    }
    @media ${media.phone} {
        height: 100%;
        flex-direction: column;
    }
`;
